export default {
  order: {},
  orders: [],
  orderForm: {},
  assetItem: {},
  assetItemFromSendOut: {},
  serviceOrderInformationForm: {},
  serviceType: { value: 'send-out' },
  dispatchAndReturnForm: {},
  notes: {},
  attachments: [],
  servicesPerformed: [],
  otherCharges: [],
  onError: {},
  reRenderComponentKey: 0,
}
