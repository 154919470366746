<template>
  <div class="pb-2">
    <l-table
      v-if="inventorySerializedAndNonSerializedVal"
      ref="lTableRef"
      :is-searchable="false"
      :module-name="INVENTORIES_MODULE_NAME"
      :table-columns="tableColumnsInventoryItems"
      :trash-list-is-uri="true"
      :table-config-options="getTableConfigOptions()"
      :can-create="ACCESS_ABILITY_INVENTORY"
    >
      <template #cell(actions)="{ data }">
        <list-table-actions
          :data="data"
          :asset-general-form="assetGeneralForm"
          :table-ref="$refs"
          :handle-open-modal="handleModal"
        />
      </template>
      <template #cell(warehouse)="{ data: {item} }">
        {{ getValueFromGivenObjectByKey(item, 'warehouse_location.warehouse.label', '—') }}
      </template>
      <template #cell(locations)="{ data: {item} }">
        <span class="d-block text-center">
          {{ getValueFromGivenObjectByKey(item, 'warehouse_location.display', '—') }}
        </span>
      </template>
      <template #cell(sn_last_digits)="{ data }">
        {{ mapSerialNumber(data.item.serial_number) }}
      </template>
      <template #cell(asset_id)="{ data }">
        {{ inventorySerializedAndNonSerializedVal ? data.item.id : data.item.amount }}
      </template>
      <template #cell(active_since)="{ data }">
        {{ formatDate(data.item.created_at) }}
      </template>
      <template #cell(current_state)="{ data: { item } }">

        <state-of-asset
          :data="item"
        />

      </template>
    </l-table>
    <l-table
      v-else
      ref="lTableRef"
      :is-searchable="false"
      :module-name="INVENTORIES_MODULE_NAME"
      :table-columns="tableColumnsInventoryItemsNonSerialized"
      :trash-list-is-uri="true"
      :table-config-options="getTableConfigOptions()"
      :can-create="ACCESS_ABILITY_INVENTORY"
    >
      <template #cell(actions)="{ data }">
        <list-table-actions-non-serialized
          :data="data"
          :table-ref="$refs"
          :asset-general-form="assetGeneralForm"
          :handle-open-modal="handleModal"
        />
      </template>
      <template #cell(warehouse)="{ data: {item} }">
        {{ getValueFromGivenObjectByKey(item, 'warehouse_location.warehouse.label', '—') }}
      </template>
      <template #cell(asset_id)="{ data: { item } }">
        {{
          inventorySerializedAndNonSerializedVal ? getValueFromGivenObjectByKey(item, 'id', '—') : getValueFromGivenObjectByKey(item, 'amount', '—')
        }}
      </template>
      <template #cell(locations)="{ data: { item } }">
        {{ getValueFromGivenObjectByKey(item, 'warehouse_location.display', '—') }}
      </template>
      <template #cell(active_since)="{ data }">
        {{ formatDate(data.item.created_at) }}
      </template>
      <template #cell(state)="{ data: { item } }">
        <state-of-asset
          :data="item"
        />
      </template>
    </l-table>
    <change-asset-to-damaged
      ref="change-asset-to-damaged"
    />

    <portal to="body-footer">
      <div class="d-flex mt-2 pb-1 justify-content-between">
        <b-button
          class="cancelBtn font-medium-1 font-weight-bolder"
          variant="outline-primary"
          @click="cancel"
        >
          {{ $t('Back to List') }}
        </b-button>
      </div>
    </portal>

  </div>
</template>
<script>

import LTable from '@/views/components/LTable/LTable.vue'
import ListTableActionsNonSerialized
from '@/views/main/inventory/inventory-view/components/ListTableActionsNonSerialized.vue'
import StateOfAsset from '@/views/main/inventory/inventory-view/components/StateOfAsset.vue'
import inventoryConfig from '@/views/main/inventory/inventoryConfig'
import { getValueFromGivenObjectByKey } from '@/@core/utils/utils'
import ChangeAssetToDamaged
from '@/views/main/inventory/inventory-view/components/ChangeAssetToDamaged/ChangeAssetToDamaged.vue'
import jwtDefaultConfig from '@/auth/jwt/jwtDefaultConfig'
import ListTableActions from '@/views/main/inventory/inventory-view/components/ListTableActions.vue'
import { BButton } from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api/dist/vue-composition-api'
import store from '@/store'
import assetGeneralModule from '@/store/settings/inventory/asset-general'
import config from './assetsConfig'

export default {
  name: 'Assets',
  components: {
    ListTableActions,
    ChangeAssetToDamaged,
    StateOfAsset,
    ListTableActionsNonSerialized,
    LTable,
    BButton,
  },
  data() {
    return {
      routeID: this.$route.params.id,
      theDateFormat: JSON.parse(localStorage.getItem(jwtDefaultConfig.storageOrganizationDateFormat)),

    }
  },
  computed: {
    assetGeneralForm() {
      return this.$store.state[this.MODULE_ASSET_NAME].assetGeneralForm
    },
    dateFormatLoaded() {
      return this.$store.state.listModule?.dateformatList.find(format => format.id === this.theDateFormat)
    },
    inventorySerializedAndNonSerializedVal() {
      return this.$store.state[this.INVENTORIES_MODULE_NAME].inventorySerializedAndNonSerializedVal
    },
    inventory() {
      return this.$store.state[this.INVENTORIES_MODULE_NAME].product
    },
  },
  mounted() {
    this.$store.dispatch(`${this.MODULE_ASSET_NAME}/getAssetGeneral`).then(res => {
      const { data } = res
      this.$store.commit(`${this.MODULE_ASSET_NAME}/SET_FORM`, data)
    })
  },
  created() {
    this.$emit('updateMenu', 'home-catalog-assets')
    this.$store.dispatch('listModule/dateformatList')
  },
  methods: {
    cancel() {
      this.$router.push({ name: 'home-main-catalog-list' })
    },
    formatDate(date) {
      if (!date) return '-----'
      date = this.convertTZ(date)
      const dateFormat = this.dateFormatLoaded
      if (!dateFormat || !dateFormat.label) return '-----'
      const formattedDate = this.customFormatDate(date, dateFormat.label)
      return formattedDate
    },
    customFormatDate(date, format) {
      const parts = date.split(/[ -]/)

      const day = parts[0]
      const month = parts[1]
      const yearFull = parts[2]
      const yearShort = yearFull.slice(-2)
      const time = date.slice(-13)

      let formattedDate = format
        .replace('dd', day)
        .replace('MM', month)
        .replace('yyyy', yearFull)
        .replace('yy', yearShort)
        .replace('/', '-')
        .replace('/', '-')

      formattedDate = `${formattedDate} ${time}`

      return formattedDate
    },
    mapSerialNumber(number) {
      if (!number) return '---'

      if (number.length > 4) {
        return `*****${number.slice(-4)}`
      }
      return number
    },
    handleModal() {
      this.$refs['change-asset-to-damaged'].show()
    },
    getTableConfigOptions() {
      return {
        endpoint: `${this.INVENTORIES_MODULE_NAME}/getInventoriesSubList`,
        data: {
          active: 1,
        },
      }
    },
  },
  setup() {
    const MODULE_ASSET_NAME = 'asset-general'

    const { CATALOGS_MODULE_NAME, INVENTORIES_MODULE_NAME, tableColumnsInventoryItemsNonSerialized } = config()
    const {
      tableColumnsInventoryItems, ACCESS_ABILITY_INVENTORY, exportDataRequest,
    } = inventoryConfig()

    if (!store.hasModule(MODULE_ASSET_NAME)) store.registerModule(MODULE_ASSET_NAME, assetGeneralModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_ASSET_NAME)) store.unregisterModule(MODULE_ASSET_NAME)
    })

    return {
      CATALOGS_MODULE_NAME,
      INVENTORIES_MODULE_NAME,
      tableColumnsInventoryItems,
      ACCESS_ABILITY_INVENTORY,
      tableColumnsInventoryItemsNonSerialized,
      exportDataRequest,
      getValueFromGivenObjectByKey,
      MODULE_ASSET_NAME,
    }
  },
}
</script>
