export default function config() {
  const CATALOGS_MODULE_NAME = 'catalogs'
  const INVENTORIES_MODULE_NAME = 'inventories'

  const tableColumnsInventoryItemsNonSerialized = [
    {
      key: 'actions',
      label: '',
      thStyle: { width: '15%' },
    },
    {
      key: 'warehouse',
      label: 'Warehouse',
    },
    {
      key: 'locations',
      label: 'Pick Location',
      thStyle: { width: '15%' },
    },
    {
      key: 'active_since',
      label: 'Active Since',
    },
    {
      key: 'state',
      label: 'Current state',
      // thStyle: { width: '65%' },
    },
  ]

  return {
    CATALOGS_MODULE_NAME,
    INVENTORIES_MODULE_NAME,
    tableColumnsInventoryItemsNonSerialized,
  }
}
