<template>
  <div class="d-flex align-items-center">
    <feather-icon
      v-b-tooltip.bottom
      icon="LRepairIcon"
      size="24"
      :title="damageTooltipText"
      class="cursor-pointer mr-1 border-dotted feather-trash-icon"
      :style="`${getAllowButton(data.item)}`"
      @click="navigateToServiceOrders(data.item)"
    />
    <feather-icon
      v-b-tooltip.bottom
      icon="LEyeIcon"
      size="24"
      class="cursor-pointer mr-1 border-dotted feather-trash-icon"
      :title="$t('View')"
      @click="navigateTo(data.item, $event, 'home-inventory-item-update')"
    />
    <feather-icon
      v-b-tooltip.bottom
      icon="LInOutIcon"
      size="24"
      :class="`cursor-pointer mr-1 border-dotted feather-trash-icon ${data.item.status === 8 ? 'defaultIconColor' : 'defaultIconColorTwo'}` "
      :title="$t('Create a Permanent Transfer')"
      @click="data.item.status !== 8 && gotoNewPage({name: 'home-inventory-transfer-order-by-asset-id', params: {id: data.item.id, product_id: data.item.product_id}}, $event)"
    />
    <feather-icon
      v-b-tooltip.bottom
      icon="LSignalIcon"
      size="24"
      :title="$t('Show asset reports')"
      class="cursor-pointer mr-1 border-dotted feather-trash-icon"
      @click="gotoNewPage({name: 'home-inventory-catalog-item-audit-inv', params: {id: data.item.product_id, inv_id: data.item.id}}, $event)"
    />

  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'
import { assetStates } from '@/enum/asset-statuses'

export default {
  name: 'ListTableActions',
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    tableRef: {
      type: Object,
      required: true,
    },
    handleOpenModal: {
      type: Function,
      required: true,
    },
    assetGeneralForm: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    damageTooltipText() {
      return !this.assetGeneralForm?.create_new_service_order_on_damage_report && this.data.item.status === assetStates.IN.code
        ? this.$t('Create Damage Report')
        : this.$t('Create a Service Order')
    },
  },
  methods: {
    navigateTo({ id }, e, name) {
      if (e.ctrlKey) {
        const route = this.$router.resolve({
          name,
          params: { inv_id: id },
        })
        window.open(route.href)
      } else {
        this.$router.push({
          name,
          params: { inv_id: id },
        })
      }
    },
    showAssetToDamagedModal({
      id, damaged, service_order_id, last_order,
    }) {
      if (damaged) {
        if (service_order_id) {
          this.$router.push({ name: 'request-service-quote-view', params: { id: service_order_id } })
        } else {
          this.$router.push({ name: 'home-orders-request-service-quote', params: { id } })
        }
      } else {
        this.$store.commit(`${this.MODULE_NAME}/SET_INVENTORY_LAST_ORDER`, last_order)
        this.$store.commit(`${this.MODULE_NAME}/SET_DAMAGED_INVENTORY_ITEM_ID`, id)
        this.$store.commit(`${this.MODULE_NAME}/SET_INVENTORIES_LIST_REF`, this.tableRef)
        this.handleOpenModal()
      }
    },
    getAllowButton(item) {
      const { status } = item
      return status === assetStates.SERVICE.code ? 'cursor:not-allowed' : 'cursor:pointer'
    },
    navigateToServiceOrders(item) {
      const { id, product_id, status } = item
      if (status === assetStates.SERVICE.code) return
      this.showAssetToDamagedModal(item)
    },
  },
  setup() {
    const MODULE_NAME = 'inventories'
    const MODULE_ASSET_NAME = 'asset-general'

    return {
      MODULE_NAME,
      MODULE_ASSET_NAME,
    }
  },
}
</script>
