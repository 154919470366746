var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-2"},[(_vm.inventorySerializedAndNonSerializedVal)?_c('l-table',{ref:"lTableRef",attrs:{"is-searchable":false,"module-name":_vm.INVENTORIES_MODULE_NAME,"table-columns":_vm.tableColumnsInventoryItems,"trash-list-is-uri":true,"table-config-options":_vm.getTableConfigOptions(),"can-create":_vm.ACCESS_ABILITY_INVENTORY},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(ref){
var data = ref.data;
return [_c('list-table-actions',{attrs:{"data":data,"asset-general-form":_vm.assetGeneralForm,"table-ref":_vm.$refs,"handle-open-modal":_vm.handleModal}})]}},{key:"cell(warehouse)",fn:function(ref){
var item = ref.data.item;
return [_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'warehouse_location.warehouse.label', '—'))+" ")]}},{key:"cell(locations)",fn:function(ref){
var item = ref.data.item;
return [_c('span',{staticClass:"d-block text-center"},[_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'warehouse_location.display', '—'))+" ")])]}},{key:"cell(sn_last_digits)",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.mapSerialNumber(data.item.serial_number))+" ")]}},{key:"cell(asset_id)",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.inventorySerializedAndNonSerializedVal ? data.item.id : data.item.amount)+" ")]}},{key:"cell(active_since)",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.formatDate(data.item.created_at))+" ")]}},{key:"cell(current_state)",fn:function(ref){
var item = ref.data.item;
return [_c('state-of-asset',{attrs:{"data":item}})]}}],null,false,2796967697)}):_c('l-table',{ref:"lTableRef",attrs:{"is-searchable":false,"module-name":_vm.INVENTORIES_MODULE_NAME,"table-columns":_vm.tableColumnsInventoryItemsNonSerialized,"trash-list-is-uri":true,"table-config-options":_vm.getTableConfigOptions(),"can-create":_vm.ACCESS_ABILITY_INVENTORY},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(ref){
var data = ref.data;
return [_c('list-table-actions-non-serialized',{attrs:{"data":data,"table-ref":_vm.$refs,"asset-general-form":_vm.assetGeneralForm,"handle-open-modal":_vm.handleModal}})]}},{key:"cell(warehouse)",fn:function(ref){
var item = ref.data.item;
return [_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'warehouse_location.warehouse.label', '—'))+" ")]}},{key:"cell(asset_id)",fn:function(ref){
var item = ref.data.item;
return [_vm._v(" "+_vm._s(_vm.inventorySerializedAndNonSerializedVal ? _vm.getValueFromGivenObjectByKey(item, 'id', '—') : _vm.getValueFromGivenObjectByKey(item, 'amount', '—'))+" ")]}},{key:"cell(locations)",fn:function(ref){
var item = ref.data.item;
return [_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'warehouse_location.display', '—'))+" ")]}},{key:"cell(active_since)",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.formatDate(data.item.created_at))+" ")]}},{key:"cell(state)",fn:function(ref){
var item = ref.data.item;
return [_c('state-of-asset',{attrs:{"data":item}})]}}])}),_c('change-asset-to-damaged',{ref:"change-asset-to-damaged"}),_c('portal',{attrs:{"to":"body-footer"}},[_c('div',{staticClass:"d-flex mt-2 pb-1 justify-content-between"},[_c('b-button',{staticClass:"cancelBtn font-medium-1 font-weight-bolder",attrs:{"variant":"outline-primary"},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t('Back to List'))+" ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }