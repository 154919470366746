<template>
  <div>
    <error-alert
      :fields="fields"
      :error="error"
    />
    <b-form>
      <validation-observer
        ref="ChangeAssetToDamagedForm"
      >
        <b-row>
          <b-col md="12">
            <component
              :is="fields[field].type"
              v-for="field in ['describe_the_issue', 'issue_type']"
              :key="field"
              :ref="`${field}_ref`"
              v-model="changeAssetToDamaged[field]"
              v-bind="getProps(field, fields)"
            />
            <p class="ml-1">
              {{ $t('Last Inspection for Order ') }}
              <b> {{ lastOrderId }}</b>
            </p>

            <component
              :is="fields['is_bill'].type"
              ref="is_bill_ref"
              v-model="changeAssetToDamaged['is_bill']"
              v-bind="getProps('is_bill', fields)"
            />
          </b-col>
        </b-row>
        <attachments />
      </validation-observer>
    </b-form>
    <div class="d-flex justify-content-between">
      <div>
        <b-button
          class="cancelBtn font-medium-1 font-weight-bolder"
          variant="outline-primary"
          :disabled="isLoading"
          @click="cancel"
        >
          <feather-icon icon="LCancelIcon" />
          {{ $t(FORM_CANCEL_BTN) }}

        </b-button>
      </div>
      <div>
        <b-button
          variant="danger"
          class="saveBtn font-medium-1 font-weight-bolder"
          style="width: 190px"
          :disabled="isLoading"
          @click="submit"
        >
          <b-spinner
            v-if="isLoading"
            small
            variant="secondary"
          />
          <span v-else>
            {{ $t(FORM_CANCEL_REPORT) }}
          </span>
        </b-button>

      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton, BCol, BForm, BRow, BSpinner,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { getProps, scrollToError } from '@/@core/utils/utils'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import jsonToFormData from '@/libs/jsonToFormData'
import store from '@/store'
import assetGeneralModule from '@/store/settings/inventory/asset-general'
import { onUnmounted } from '@vue/composition-api'
import serviceModule from '@/store/main/orders/service'
import Attachments from './Attachment.vue'
import config from '../config'

export default {
  name: 'Form',
  components: {
    BSpinner,
    BRow,
    BCol,
    BForm,
    BButton,
    ErrorAlert,
    Attachments,
    ValidationObserver,
  },
  props: {
    fulfilmentOrder: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      error: { },
      isLoading: false,
    }
  },
  computed: {
    changeAssetToDamaged() {
      return this.$store.state[this.MODULE_NAME].changeAssetToDamagedForm
    },
    damagedInventoryItemID() {
      return this.$store.state[this.MODULE_NAME].damagedInventoryItemID
    },
    lastOrderId() {
      return this.$store.state[this.MODULE_NAME].inventoriesLastOrder || '-'
    },
    assetGeneralForm() {
      // create_new_service_order_on_damage_report
      return this.$store.state[this.MODULE_ASSET_NAME].assetGeneralForm
    },
    inventoriesListRef() {
      return this.$store.state[this.MODULE_NAME].inventoriesListRef
    },
  },
  mounted() {
    this.$store.dispatch(`${this.MODULE_ASSET_NAME}/getAssetGeneral`).then(res => {
      const { data } = res
      this.$store.commit(`${this.MODULE_ASSET_NAME}/SET_FORM`, data)
    }).catch(err => {
      console.log(err.response?.data)
    })
  },
  methods: {
    resetForm() {
      this.$store.commit(`${this.MODULE_NAME}/SET_CHANGE_ASSET_TO_DAMAGED_FORM`, {
        attachments: [],
      })
    },
    cancel() {
      this.resetForm()
      this.$emit('onModalHide')
    },
    submit() {
      this.$refs.ChangeAssetToDamagedForm.validate().then(success => {
        if (success) {
          this.isLoading = true

          const {
            attachments,
            describe_the_issue,
            issue_type,
            is_bill,
          } = this.changeAssetToDamaged

          const mappedData = {
            [this.fulfilmentOrder ? 'order_product_id' : 'inventory_item_id']: this.damagedInventoryItemID,
            describe_the_issue,
            issue_type: issue_type.id,
            is_bill: is_bill ? 1 : 0,
            attachments,
          }

          const mappedDataToJson = jsonToFormData(mappedData)

          this.sendNotification(this, mappedDataToJson, `${this.MODULE_NAME}/${
            this.fulfilmentOrder ? 'ordersDamagedReport' : 'damageInventory'
          }`, 'Damaged Asset Reported successfully')
            .then(resp => {
              const { data } = resp.data
              if (this.fulfilmentOrder) {
                this.$emit('orderDamagedReported')
              } else if (this.assetGeneralForm.create_new_service_order_on_damage_report && data?.length > 0) {
                this.$router.push({ name: 'request-service-quote-view', params: { id: data[0].service_order_id } })
              } else {
                this.inventoriesListRef.lTableRef.refetchData()
              }

              this.cancel()
            })
            .catch(err => {
              this.error = err.response?.data
            })
            .finally(() => {
              this.isLoading = false
            })
        } else {
          scrollToError(this, this.$refs.ChangeAssetToDamagedForm)
        }
      })
    },
    serviceOrderStore(damage, mappedData) {
      const payload = {
        inventory_item_damaged_id: damage[0].id,
        action: 'draft',
        describe_the_issue: mappedData?.describe_the_issue,
        is_bill: mappedData?.is_bill,
        issue_type: mappedData?.issue_type,
      }
      this.$store.dispatch(`${this.MODULE_SERVICE_NAME}/send-out`, payload)
        .then(resp => {
          const { data } = resp.data
          const { id, inventory_item_damaged } = data[0]
          this.$store.commit(`${this.MODULE_NAME}/SET_ASSET_ITEM_FROM_SEND_OUT`, inventory_item_damaged)
          this.$router.push({ name: 'home-orders-request-service-quote', params: { id: inventory_item_damaged.inventory_item_id } })
          this.cancel()
        })
    },
  },
  setup() {
    const MODULE_NAME = 'inventories'
    const MODULE_ASSET_NAME = 'asset-general'
    const MODULE_SERVICE_NAME = 'service-order'

    if (!store.hasModule(MODULE_ASSET_NAME)) store.registerModule(MODULE_ASSET_NAME, assetGeneralModule)
    if (!store.hasModule(MODULE_SERVICE_NAME)) store.registerModule(MODULE_SERVICE_NAME, serviceModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_ASSET_NAME)) store.unregisterModule(MODULE_ASSET_NAME)
      if (store.hasModule(MODULE_SERVICE_NAME)) store.unregisterModule(MODULE_SERVICE_NAME)
    })
    const { fields, FORM_CANCEL_BTN, FORM_CANCEL_REPORT } = config()

    return {
      fields,
      getProps,
      MODULE_NAME,
      MODULE_ASSET_NAME,
      MODULE_SERVICE_NAME,
      FORM_CANCEL_BTN,
      FORM_CANCEL_REPORT,
    }
  },
}
</script>
